import React, { useContext, useState, useEffect } from "react";
import loginimg from '../assets/images/login.png'
import accessibility from '../assets/icons/accessibility.svg'
import logo from '../assets/images/logo.png'
import { useNavigate } from 'react-router-dom'
import { LoginContext } from '../LoginContext';
import dropd from '../assets/img/down.png'
import FocusVisible from "./FocusVisible";

import '../assets/styles/login/style-hebrew.css'

// import '../assets/styles/nav/home.css';
//import '../assets/styles/login/style-hebrew.css'
export default function Loginrtl() {
  {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [err, setErr] = useState('');
    const [forgetMsg, setForgetMsg] = useState('');
    const [translate, setTransate] = useState()
    const [msg, setMsg] = useState('')
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const navigate = useNavigate();

    const { loggedIn, setLoggedIn } = useContext(LoginContext);


    useEffect(() => {

      //  fetch('https://localhost:44318/api/UserSettings/GetReadupHe')
      fetch('https://testapi.readupp.com/api/UserSettings/GetReadupHe')
        .then(async response => {
          const data = await response.json();
          //debugger;
          setTransate(data);

        })
        .catch(error => {

        });




    }, [])
   
    const doLogin = (event) => {
      event.preventDefault(); // מונע את הרענון של הדף
      localStorage.exp = "none";
      setForgetMsg('')
      setErr(false)
      const credentials = { email, password }

      //  fetch('https://api.vayikra-bible.com/Login/login',{
      fetch('https://testapi.readupp.com/Login/login', {
        //   fetch('https://localhost:44318/Login/login',{
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(credentials)
      })
        .then(async response => {
          let data;
          const contentType = response.headers.get('content-type');

          // Check if the response content type is JSON
          if (contentType && contentType.includes('application/json')) {
            data = await response.json();
          } else {
            data = await response.text();
          }

          // If the response is not ok, throw an error with specific message
          if (!response.ok) {
            if (response.status === 500) {
              throw new Error("ארע שגיאה במהלך החיבור");
            } else if (response.status === 401) {
              throw new Error("איימל או סיסמא לא תקינים");
            } else if (response.status === 400) {
              throw new Error("משתמש זה מוקפא כרגע");
            } else {
              // Handle other errors
              throw new Error(data);
            }
          }

          return data;
        })
        .then(data => {
          if (data.ok) {
            localStorage.loginDate = Date.now();
            localStorage.userId = data.usrId;
            localStorage.role = data.role;
            localStorage.email = data.email;
            localStorage.orgid = data.orgid;
            localStorage.exp = data.exp==false?'none':'block';
            localStorage.loginKey = data.token;
            navigate('/booksrtl');
          }
        })
        .catch(error => {
          setMsg(error.message);
          //setErr(true);
        });
    };


    const toggleDropdown = () => {
      var dropdown = document.getElementById("dropdown");
      if (dropdown != null) {
        dropdown.classList.toggle("show");
      }
    };
    const doForget = () => {
      setForgetMsg('')
      setErr(false)
      const credentials = { email, password }

      if (email == '') {
        setForgetMsg('Email is missing')
      }

      //  fetch('https://api.vayikra-bible.com/Login/login',{
      fetch('https://testapi.readupp.com/Login/forgot', {
        //   fetch('https://localhost:44318/Login/login',{
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(credentials)
      })
        .then(response => response.json())
        .then(data => {
          //debugger;
          if (email != '')
            setErr(!data.res);
          if (data.res) {
            setForgetMsg('נשלח איימל.')
          }
        })
        .catch(() => {
          if (email != '')
            setErr(true);
        });
    }
    const handleKeyDown = (e) => {
      if (e.key === 'Enter' || e.key === ' ') {
        toggleDropdown();
      } else if (e.key === 'Escape' && dropdownOpen) {
        setDropdownOpen(false);
      }
    };



    return (
      <body>
        <div className="top" dir="rtl">
          <div className="left"></div>
          <div className="right">
            <div className="lang" id="myDropdown"
                onClick={toggleDropdown}
                onKeyDown={handleKeyDown}
                tabIndex={0}
                aria-haspopup="true"
                aria-expanded={dropdownOpen}>
              <p>עברית</p>
              <img src={dropd} alt="חץ הורדה" />
              <div className="dropdown" id="dropdown">
                <a href="/">English</a>
              </div>
            </div>
            <div className="logoImg">
              <a href="#" className="logo">
                <img src={logo} alt="לוגו" />
              </a>
            </div>
            <h1>{translate != null ? translate[1].value : 'Welcome'}</h1>
            <h5>{translate != null ? translate[2].value : 'Log in'}</h5>

            <form action="#" onSubmit={(e) => doLogin(e)}>
              <input
                className="input"
                type="text"
                name="email"
                id="email"
                placeholder={translate != null ? translate[88].value : 'E-mail'}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <input
                className="input"
                type="password"
                name="password"
                id="password"
                placeholder={translate != null ? translate[87].value : 'Password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div className="col-12">
                <span className="w-100 red" type="text" name>
                  {msg}
                </span>
              </div>
              {err ? (
                <div className="col-12">
                  <span className="w-100 red" type="text" name>
                    {translate != null ? translate[100].value : 'E-mail or password incorrect'}
                  </span>
                </div>
              ) : null}

              <div className="row">
                <label className="custom-checkbox">
                  <input type="checkbox"
                   onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      e.preventDefault(); // Prevent default behavior (e.g., scrolling)
                      e.stopPropagation(); // Stop event from propagating to the form
                      e.target.click(); // Simulate a click to toggle the checkbox
                    }
                  }} />
                  <span className="checkmark"></span>
                  <p>{translate != null ? translate[3].value : 'Keep me logged'}</p>
                </label>
                <FocusVisible>
                  <button
                    type="sumbit"
                    aria-label={translate != null ? translate[2].value : 'Log in'}>
                    {translate != null ? translate[2].value : 'Log in'}
                  </button>
                </FocusVisible>
              </div>
              {/* <p className="fPassword" > {forgetMsg}</p> */}


              <a className="fPassword" style={{ float: 'left' }} href="#" onClick={doForget}>
                {translate != null ? translate[4].value : 'forgot password?'}
              </a>
              <p className="w-100 red" type="text" name>{forgetMsg}</p>

              <br />
              <div className="line"></div>
              <div className="dontHaveAccount">
                <p dir="ltr">
                  {translate != null ? translate[5].value : "Don't have an account?"}{' '}
                  <a href="/registerrtl">{translate != null ? translate[6].value : 'Register'}</a>
                </p>
              </div>


            </form>
            <div className="accessibilitystyle">
              <p dir="ltr">
                <img src={accessibility} alt="נגישות" />
                <a href="/Accessibility">
                  {translate != null ? translate[0].value : 'Accessibility statement'}
                </a>
              </p>
            </div>
          </div>
        </div>
      </body>
    );
  }

}