import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'
import back from '../assets/img/backArrow.svg'
import searchIcon from '../assets/img/searchIcon.svg'
import Nav from './NavBar';
import FocusVisible from './FocusVisible';

import '../assets/styles/users/user.css';
import Footer from './Footer';
//import { unstable_useIsFocusVisible as useIsFocusVisible } from '@mui/utils';

// import '../assets/styles/nav/home.css'

function Users() {
  const navigate = useNavigate();
  const [translate, setTransate] = useState({})
  const [filteredUsers, setFilteredUsers] = useState([]);

  const [org, setOrg] = useState({});
  const [usersOrg, setUsersOrg] = useState([]);
  const [currentRole, setCurrentRole] = useState('Organization Manager');
  const [roles] = useState(['Organization Manager', 'Teacher', 'Student']);
  const [search, setSearch] = useState('');

  let roleUser = localStorage.getItem('role');
  const localStorageUserId = parseInt(localStorage.getItem('userId'), 10);

  useEffect(() => {
    setFilteredUsers(usersOrg.filter((user) => {
      if (currentRole === 'Organization Manager') {
        return user.role === 'Admin' || user.role === 'Organization Manager';
      }
      if (currentRole === 'Student') {
        return user.role === '' || user.role === 'Student'; // Assuming students have an empty string as their role
      }
      return user.role === currentRole;
    }));
  }, [currentRole, usersOrg]);
  // const { isFocusVisibleRef, onFocus, onBlur } = useIsFocusVisible();

  // const [focusVisible, setFocusVisible] = useState({});

  // const handleFocus = (role) => (event) => {
  //   onFocus(event);
  //   if (isFocusVisibleRef.current) {
  //     setFocusVisible((prev) => ({ ...prev, [role]: true }));
  //   }
  // };

  // const handleBlur = (role) => (event) => {
  //   onBlur(event);
  //   setFocusVisible((prev) => ({ ...prev, [role]: false }));
  // };


  const FreezeUsersOrg = (id, freeze) => {
    fetch(`https://testapi.readupp.com/Login/FreezeUsersOrg?orgUserId=${localStorage.userId}&id=${id}&freeze=${freeze}`, {
      //fetch(`https://localhost:44318/Login/FreezeUsersOrg?orgUserId=${localStorage.userId}&id=${id}&freeze=${freeze}`,{
      //    fetch('https://localhost:44318/Login/PurchasesSucceed',{
      method: 'POST',
      headers: { "Content-Type": "application/json" },

    })
      .then(async response => {
        const data = await response.json();
        console.log(data);
        setUsersOrg(usersOrg.map(user => user.userId === id ? { ...user, freeze: freeze } : user));

      })
      .catch(error => {
        console.error('Error fetching translation data:', error);
      });

  }
  const doSearch = () => {
    const tmp = usersOrg.filter(user =>
      user.firstName.toLowerCase().includes(search.toLowerCase()) || user.lastName.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredUsers(tmp);
  };


  // Fetch organization and users data on component mount
  useEffect(() => {
    fetch('https://testapi.readupp.com/api/UserSettings/GetReadupEn')
      .then(async response => {
        const data = await response.json();
        setTransate(data);
      })
      .catch(error => {
        console.error('Error fetching translation data:', error);
      });

    fetch(`https://testapi.readupp.com/Login/GetOrg?userid=${localStorage.userId}&orgid=-1`)
      .then(async response => {
        const data = await response.json();
        setOrg(data);

        // Fetch all users in the organization
        return fetch(`https://testapi.readupp.com/Login/getUsersOrg?orgID=${data.orgId}`);
      })
      .then(async response => {
        const data = await response.json();
        setUsersOrg(Array.isArray(data) ? data : []); // Set users
      })
      .catch(error => {
        console.error('Error fetching organization data:', error);
      });
  }, []);

  // Filter users based on current role
  // const filteredUsers = usersOrg.filter((user) => {
  //   if (currentRole === 'Organization Manager') {
  //     return user.role === 'Admin' || user.role === 'Organization Manager';
  //   }
  //   if (currentRole === 'Student') {
  //     return user.role === ''  ; // Assuming students have an empty string as their role
  //   }
  //   return user.role === currentRole;
  // });

  // Change role and fetch users accordingly
  const openRole = (event, role) => {
    setCurrentRole(role);
    //   fetchUsers(role);
  };
  const handleKeyDown = (e, callback, ...args) => {
    if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault(); // Prevent default scrolling for space
        callback(...args); // Call the dynamic function with the provided arguments
    }
};


  const convertTimeToHoursAndMinutes = (time) => {
    if (time) {
      const [hours, minutes] = time.split(':').map(Number);
      return `${hours}:${minutes.toString().padStart(2, '0')}`; // Ensure two digits for minutes
    }
    return null; // Optional: return null if time is not provided
  };

  const View = (id) => {
    navigate(`/account${id ? `?id=${id}` : ''}`);
  };


  return (
    <>
      <Nav nav="/Usersrtl" />
      <div className="UmainContent" dir="ltr">
        <div className="UtopHeader UindividualHeader">
          <div className="Uleft">
            <h2>Users</h2>
          </div>
          <div className="Uright UtotalUser">
            <h4>Total - Users:</h4>
            <p>
              <span>{org.chaptersCounter}</span> Chapters read &nbsp; |
            </p>
            &nbsp;&nbsp;
            <p>
              <span>{convertTimeToHoursAndMinutes(org.readTimer)}</span> Hours of reading
            </p>
          </div>
          {/* <div className="UbackPage">
            <a href="/books" style={{ textDecoration: 'none' }}>
              <img src={back} alt="back" />
            </a>
          </div> */}
        </div>
        <h2 id="chapterMobile">Chapters</h2>
        <div className="UprogressChapter" style={{ minHeight: '85vh' }}>
          <div className="Usearch">
            <h2></h2>
            <FocusVisible>
              <div className="UsearchInput">
                <input className="Uinput" type="text" name="search" id="Search" 
                onKeyDown={(e)=>handleKeyDown(e,doSearch)}
                onChange={(e) => setSearch(e.target.value)} placeholder="Search" />
                <img src={searchIcon} alt="search Icon" onClick={doSearch} />
              </div>
            </FocusVisible>
          </div>
          <div className="roleTabs" style={{ borderBottom: '1px solid #ACACAC' }}>
            {roles.map((role) => (
              <FocusVisible key={role} className={`tablinks ${currentRole === role ? 'active' : ''}`}>
                <button onClick={(event) => openRole(event, role)}>{role}</button>
              </FocusVisible>
            ))}
          </div>
          <div className="UcontentTab">
            {roles.map((role) => (
              <div
                key={role}
                id={role}
                className="Utabcontent"
                style={{ display: currentRole === role ? 'block' : 'none' }}
              >
                {filteredUsers.map((user, index) => (
                  <div className={`Urow ${user.freeze ? 'freezeBackground' : ''}`} key={index}>
                    <div className="Uleft">
                      <h2>{user.firstName} {user.lastName}</h2>
                      <div className="UprogressBar">
                        <p className="Ucompleted">{user.role}</p>
                        <p className="Ucompleted">{user.chaptersCounter} chapters read</p>
                        <p className="Ucompleted">{convertTimeToHoursAndMinutes(user.readTimer)} hours of reading</p>
                      </div>
                    </div>
                    {((roleUser === 'Admin' || roleUser === 'Organization Manager') || (roleUser === 'learning central manager' && user.role === 'Student')) && (
                      <div className="UbtnsList">
                        <FocusVisible>
                          <a href="#" onClick={() => View(user.userId)} className="Uview blue">
                            View
                          </a>
                        </FocusVisible>
                        {(roleUser === 'Admin' || roleUser === 'Organization Manager')
                         && user.userId !== localStorageUserId && (
                          <FocusVisible>
                            <a
                             onClick={() => FreezeUsersOrg(user.userId, !user.freeze)} 
                             className="Uview blue">
                              {user.freeze ? 'Unfreeze' : 'Freeze'}
                            </a>
                          </FocusVisible>
                        )}
                      </div>
                    )}


                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer lang={'en'} />
    </>
  );
};

export default Users;