import Nav from "./NavBar.rtl";
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'
import '../assets/styles/users/userrtl.css';
import back from '../assets/img/backHebrew.svg';
import searchIcon from '../assets/img/searchIcon.svg';
import Navrtl from "./NavBar.rtl";
import Footer from "./Footer";
import FocusVisible from "./FocusVisible";

function Usersrtl() {




  const navigate = useNavigate();

  const [translate, setTransate] = useState({})
  const [filteredUsers, setFilteredUsers] = useState([]);

  const [org, setOrg] = useState({});
  const [usersOrg, setUsersOrg] = useState([]);
  const [currentRole, setCurrentRole] = useState('Organization Manager');
  const [roles] = useState(['Organization Manager', 'learning central manager', 'Student']);
  const [search, setSearch] = useState('');

  let roleUser = localStorage.getItem('role');

  const localStorageUserId = parseInt(localStorage.getItem('userId'), 10);

  const roleMapping = {
    'Organization Manager': 'מנהל ארגון',
    'Admin': 'מנהל',
    'learning central manager': 'מורה',
    'Student': 'תלמידים',
    '': 'תלמידים' // Assuming students have an empty string as their role
  };
  useEffect(() => {
    setFilteredUsers(usersOrg.filter((user) => {
      if (currentRole === 'Organization Manager') {
        return user.role === 'Admin' || user.role === 'Organization Manager';
      }
      if (currentRole === 'Student') {
        return user.role === '' || user.role ==='Student'; // Assuming students have an empty string as their role
      }
      return user.role === currentRole;
    }));
  }, [currentRole, usersOrg]);

  const FreezeUsersOrg = (id, freeze) => {
    fetch(`https://testapi.readupp.com/Login/FreezeUsersOrg?orgUserId=${localStorage.userId}&id=${id}&freeze=${freeze}`,{
    //fetch(`https://localhost:44318/Login/FreezeUsersOrg?orgUserId=${localStorage.userId}&id=${id}&freeze=${freeze}`,{
      //    fetch('https://localhost:44318/Login/PurchasesSucceed',{
              method:'POST',
              headers:{"Content-Type":"application/json"},
              
          })
      .then(async response => {
        const data = await response.json();
       console.log(data);
       setUsersOrg(usersOrg.map(user => user.userId === id ? { ...user, freeze: freeze } : user));

      })
      .catch(error => {
        console.error('Error fetching translation data:', error);
      });

  } 
   const doSearch = () => {
    const tmp = usersOrg.filter(user =>
        user.firstName.toLowerCase().includes(search.toLowerCase()) ||   user.lastName.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredUsers(tmp);
};  const handleKeyDown = (e, callback, ...args) => {
  if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault(); // Prevent default scrolling for space
      callback(...args); // Call the dynamic function with the provided arguments
  }
};

  useEffect(() => {
    fetch('https://testapi.readupp.com/api/UserSettings/GetReadupHe')
      .then(async response => {
        const data = await response.json();
        setTransate(data);
      })
      .catch(error => {
        console.error('Error fetching translation data:', error);
      });

    fetch(`https://testapi.readupp.com/Login/GetOrg?userid=${localStorage.userId}&orgid=-1`)
      .then(async response => {
        const data = await response.json();
        setOrg(data);

        // Fetch all users in the organization
        return fetch(`https://testapi.readupp.com/Login/getUsersOrg?orgID=${data.orgId}`);
      })
      .then(async response => {
        const data = await response.json();
        setUsersOrg(Array.isArray(data) ? data : []); // Set users
      })
      .catch(error => {
        console.error('Error fetching organization data:', error);
      });
  }, []);


  const openRole = (event, role) => {
    setCurrentRole(role);
    //   fetchUsers(role);
  };


  const convertTimeToHoursAndMinutes = (time) => {
    if (time) {
      const [hours, minutes] = time.split(':').map(Number);
      return `${hours}:${minutes.toString().padStart(2, '0')}`; // Ensure two digits for minutes
    }
    return null; // Optional: return null if time is not provided
  };
  
  const View = (id) => {

    navigate(`/accountrtl${id ? `?id=${id}` : ''}`);
  }


  return (<>
    <Navrtl nav="/Users" />
    <div className="UhmainContent" >
      <div className="UhtopHeader  UhindividualHeader" >
        <div className="Uhleft" >
          <h2>משתמשים</h2>
        </div>
        <div className="Uhright UhtotalUser" >
          <h4>סה”כ כלל המשתמשים:</h4>
          <p><span>{org.chaptersCounter}</span> פרקים שנקראו |</p>
          &nbsp;&nbsp;
          <p><span>{convertTimeToHoursAndMinutes(org.readTimer)}</span> שעות קריאה</p>
        </div>
        {/* <div className="UhbackPage" style={{ direction: "ltr" }}>
          <a href="/booksrtl" style={{ textDecoration: ' none;' }}><img src={back} alt="חזור" /></a>
        </div> */}
      </div>
      {/* <!-- <h2 id="chapterMobile">Chapters</h2> --> */}
      <div className="UhprogressChapter" style={{ minHeight: '85vh;' }}>
        <div className="Uhsearch">
          <h2></h2>
          <FocusVisible>
          <div className="UhsearchInput">
            <input
              className="Uhinput"
              type="text"
              name="search"
              id="search"
              onKeyDown={(e)=>handleKeyDown(e,doSearch)}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="חיפוש משתמש..."
            />
            <img src={searchIcon} alt="אייקון חיפוש"   onClick={doSearch}/>
          </div>
          </FocusVisible>
        </div>
        <div className="UhroleTabs" style={{ borderBottom: '1px solid #ACACAC' }}>
          {roles.map((role) => (
            <FocusVisible>
            <button
              key={role}
              className={`tablinks ${currentRole === role ? 'active' : ''}`}
              onClick={(event) => openRole(event, role)}
            >
                             {roleMapping[role]}

            </button>
            </FocusVisible>
          ))}
        </div>

        <div className="UhcontentTab" style={{ marginBottom: '70px;' }}>
          {roles.map((role) => (
            <div
              key={role}
              id={role}
              className="Uhtabcontent"
              style={{ display: currentRole === role ? 'block' : 'none' }}
            >

              {filteredUsers.map((user, index) => (
                <div key={index} className={`Uhrow ${user.freeze ? 'freezeBackground' : ''}`}>
                  <div className="Uhleft">
                    <h2>{user.firstName} {user.lastName}</h2>
                    <div className="UhprogressBar">
                      <p className="Uhcompleted">{roleMapping[user.role]}</p>
                      <p className="Uhcompleted">{user.chaptersCounter} פרקים שנקראו</p>
                      <p className="Uhcompleted">{convertTimeToHoursAndMinutes(user.readTimer)} שעות של קריאה</p>
                    </div>
                  </div>
                  {((roleUser === 'Admin' || roleUser === 'Organization Manager') || (roleUser === 'learning central manager' && user.role === 'Student')) && (

                  <div className="UhbtnsList">
                    <FocusVisible>
                    <a  href="#" onClick={() => View(user.userId)} 
                    className="Uhview blue">כניסה </a>
                    </FocusVisible>

                    {(roleUser === 'Admin' || roleUser === 'Organization Manager') 
                    && user.userId !== localStorageUserId &&
                   <FocusVisible>
                   <a  href="#"
                   onClick={() => FreezeUsersOrg(user.userId,!user.freeze)}
                     className="Uhview blue">  
                     {user.freeze ? 'יציאה מהקפאה' : 'הקפאה '}</a>
                    
                     </FocusVisible> }

                  </div>
                    )}

                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
   
 <Footer lang={'he'} />
  </> 
 
  ) 
 
}
export default Usersrtl;