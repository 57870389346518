import Nav from "./NavBar";
import React, { useState, useEffect } from "react";
import searchIcon from '../assets/img/searchIcon.svg';
import '../assets/styles/nav/home.css'
import selectDown from '../assets/img/selectDown.svg';
import arrowDown from '../assets/img/arrowDown.svg';
import cancel from '../assets/img/cancel.svg';
import axios from "axios";
import FocusVisible from "./FocusVisible";
import Footer from "./Footer";

function Library() {
    const [libraries, setLibraries] = useState([]);
    const [filteredLibraries, setFilteredLibraries] = useState(libraries);

    const [search, setSearch] = useState('');
    const localStorageUserId = parseInt(localStorage.getItem('userId'), 10);
    const [libraryID, setLibraryID] = useState()
    const [translate, setTransate] = useState()
    const [visibilitylValidation, setVisibilitylValidation] = useState('');
    const [NameValidation, setNameValidation] = useState('');
    const [libraryEdit, setLibraryEdit] = useState({
        id: "",
        libraryName: "",
        visibility: ""
    })





    const openMyChapter = (evt, tabName) => {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
        document.getElementById(tabName).style.display = "block";
        evt.currentTarget.className += " active";
    }

    const openLibraryAddModal = () => {
        setLibraryEdit({
            libraryName: "",
            visibility: "",
        })
        var addLibraryModalTop = document.getElementById("addLibraryModalTop");
        if (addLibraryModalTop) {
            addLibraryModalTop.style.display = "block";
            addLibraryModalTop.focus();
        }
    }

    const closeLibraryAddModal = () => {
        var addLibraryModalTop = document.getElementById("addLibraryModalTop");
        if (addLibraryModalTop) {
            addLibraryModalTop.style.display = "none";
        }
    }

    // edit modal library 
    const openLibraryEditModal = (library) => {
        var editLibraryModalTop = document.getElementById("editLibraryModalTop");
        if (editLibraryModalTop) {
            editLibraryModalTop.style.display = "block";
            editLibraryModalTop.focus();
        }

        setLibraryEdit({
            id: library.id,
            libraryName: library.libraryName,
            visibility: library.visibility,
        })

    }

    const closeLibraryEditModal = () => {
        var editLibraryModalTop = document.getElementById("editLibraryModalTop");
        if (editLibraryModalTop) {
            editLibraryModalTop.style.display = "none";
        }
    }

    // library delete modal
    const openLibraryDeleteModal = (library) => {
        var deleteLibraryModalTop = document.getElementById("deleteLibraryModalTop");
        if (deleteLibraryModalTop) {
            deleteLibraryModalTop.style.display = "block";
            deleteLibraryModalTop.focus();
        }
        setLibraryEdit({
            id: library.id,
            libraryName: library.libraryName,
            visibility: library.visibility,
        })

    }
    const doSearch = () => {
        const tmp = libraries.filter(library =>
            library.libraryName.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredLibraries(tmp);
    };
    const closeLibraryDeleteModal = () => {
        var deleteLibraryModalTop = document.getElementById("deleteLibraryModalTop");
        if (deleteLibraryModalTop) {
            deleteLibraryModalTop.style.display = "none";
        }
    }
    const handleKeyDown = (e, callback, ...args) => {
        if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault(); // Prevent default scrolling for space
            callback(...args); // Call the dynamic function with the provided arguments
        }
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log("Name:", name, "Value:", value);
        setLibraryEdit({
            ...libraryEdit,
            [name]: value
        });
    };
    const Delete = (id) => {
        const url = 'https://testapi.readupp.com/DeleteLibrary?id=' + id;
        // const url = 'https://localhost:44318/DeleteLibrary?id=' + id;

        axios.post(url)
            .then(async response => {

                const data = await response.data;
                if (data) {
                    const updatedlibrary = data;
                    const updatedlibrarys = libraries.filter(library => library.id !== id);

                    setLibraries(updatedlibrarys);
                    closeLibraryDeleteModal();
                }

            });

    }
    const Add = (e) => {
        e.preventDefault();
        let validation = true;

        if (!libraryEdit.visibility) {
            setVisibilitylValidation("1px solid red")
            validation = false;
        }

        if (validation) {
            const url = 'https://testapi.readupp.com/AddLibrary';
            const formData = new FormData();
            formData.append('userId', localStorageUserId);
            formData.append('libraryName', libraryEdit.libraryName);
            formData.append('visibility', libraryEdit.visibility);

            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            };

            axios.post(url, formData, config)
                .then((response) => {
                    if (response && response.data) {
                        const newLibrary = response.data;
                        console.log('New library added:', newLibrary);

                        setLibraries((prevLibraries) => {
                            const updatedLibraries = [...prevLibraries, newLibrary];
                            console.log('Updated libraries:', updatedLibraries);
                            return updatedLibraries;
                        });

                        setLibraryEdit({
                            libraryName: "",
                            visibility: "",
                        })
                        closeLibraryAddModal();
                    }
                })
                .catch((error) => {
                    console.error("There was an error updating the library!", error);
                });
        }
    };




    const Save = (e) => {
        e.preventDefault();
        let validation = true;
        if (!libraryEdit.libraryName) {
            setNameValidation("1px solid red")
            validation = false;
        }

        if (validation) {


            const url = 'https://testapi.readupp.com/UpdateLibrary';
            //  const url = 'https://localhost:44318/UpdateLibrary';
            const formData = new FormData();
            formData.append('id', libraryEdit.id);
            formData.append('libraryName', libraryEdit.libraryName);
            formData.append('visibility', libraryEdit.visibility);
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            };

            axios.post(url, formData, config)
                .then((response) => {
                    console.log(response.data);
                    const updatedlibrary = response.data;

                    const updatedlibrarys = libraries.map((library) =>
                        library.id === updatedlibrary.id ? updatedlibrary : library
                    );
                    setLibraries(updatedlibrarys);
                    setLibraryEdit({
                        libraryName: "",
                        visibility: "",
                    })
                    closeLibraryEditModal();
                })
                .catch((error) => {
                    console.error("There was an error updating the library!", error);
                });
        }
    };

    useEffect(() => {
        doSearch();  // Perform search whenever `search` state changes
    }, [search, libraries]); // Re-run search when libraries or search term changes


    useEffect(() => {
        fetch('https://testapi.readupp.com/getAllLibrarys?Id=' + localStorage.userId)
            .then(async response => {
                const data = await response.json();
                setFilteredLibraries(data);
                setLibraries(data);
            });
        fetch('https://testapi.readupp.com/api/UserSettings/GetReadupEn')
            .then(async response => {
                const data = await response.json();
                setTransate(data);
            })
            .catch(error => {
                console.error('There was an error fetching user settings!', error);
            });


    }, []);

    const toggleDropDown = (index) => {
        const dropdown = document.getElementById(`dropdown-${index}`);
        dropdown.style.display = dropdown.style.display === "block" ? "none" : "block";
    };


    return (
        <>
            <Nav nav="/Libaryrtl" />
            <div dir="ltr" className="mainContent" tabIndex={"-1"} style={{ minHeight: '85vh' }}>
                <div className="topHeader">
                    <div className="left">
                        <h2 style={{ fontSize: '28px', fontWeight: 700 }}>{translate != null ? translate[108].value : 'Libraries'}</h2>
                    </div>

                    <div className="right addLibrary">
                        <FocusVisible>
                            <button style={{ cursor: 'pointer' }} onClick={openLibraryAddModal}>+ {translate != null ? translate[156].value : 'Add Library'}</button>
                        </FocusVisible>
                    </div>

                </div>
                <h2 id="chapterMobile">Library</h2>
                <div className="progressChapter addLibTop">
                    <div className="search" style={{ borderBottom: '1px solid #acacac' }}>
                        <h2></h2>
                        <FocusVisible>
                            <div className="searchInput">
                                <input
                                    className="input"
                                    type="text"
                                    name="search"
                                    id="search"
                                    placeholder="Search Library"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                                <img src={searchIcon} alt="search Icon" onClick={doSearch} style={{ cursor: 'pointer' }} />
                            </div>
                        </FocusVisible>
                    </div>

                    <div className="contentTab" style={{ marginBottom: '70px' }}>
                        <div id="chapter1-10" className="tabcontent">
                            {filteredLibraries.map((library, index) => (
                                <div className="row" key={library.id}>
                                    <div className="left">
                                        <h2 style={{ textAlign: 'left' }}>{library.libraryName}</h2>
                                        <div className="progressBar">
                                            <p className="completed">{library.number} {translate != null ? translate[111].value : 'Books'}</p>
                                        </div>
                                    </div>
                                    {library.id === 1 && libraries.find(l => l.id === 1 && localStorageUserId === l.userId) ? (
                                        <FocusVisible>
                                            <div className="circle"
                                                tabIndex="0"
                                                onClick={() => toggleDropDown(index)}
                                                onKeyDown={(e) => handleKeyDown(e, toggleDropDown, index)}>
                                                ...
                                                <div className="dropDownTable" style={{ display: 'none' }} id={`dropdown-${index}`}>
                                                    <div className="inner">
                                                        <div className="square" tabIndex={1}></div>

                                                        <FocusVisible>
                                                            <button onClick={() => openLibraryEditModal(library)}
                                                                onKeyDown={(e) => handleKeyDown(e, openLibraryEditModal, library)}
                                                            >{translate != null ? translate[154].value : 'Edit Library'}</button>
                                                        </FocusVisible>
                                                        <FocusVisible>
                                                            <button tabIndex={0} onClick={() => openLibraryDeleteModal(library)}
                                                                onKeyDown={(e) => handleKeyDown(e, openLibraryDeleteModal, library)}
                                                            >{translate != null ? translate[155].value : 'Delete Library'}</button>
                                                        </FocusVisible>

                                                    </div>
                                                </div>
                                            </div>
                                        </FocusVisible>
                                    ) : (library.id !== 1 &&
                                        <FocusVisible>
                                            <div className="circle"
                                                tabIndex="0"
                                                onClick={() => toggleDropDown(index)}
                                                onKeyDown={(e) => handleKeyDown(e, toggleDropDown, index)}>
                                                ...
                                                <div className="dropDownTable" style={{ display: 'none' }} id={`dropdown-${index}`}   >
                                                    <div className="inner">
                                                        <div className="square" tabIndex={1}></div>
                                                        <FocusVisible>
                                                            <button onClick={() => openLibraryEditModal(library)}
                                                                onKeyDown={(e) => handleKeyDown(e, openLibraryEditModal, library)}
                                                            >{translate != null ? translate[154].value : 'Edit Library'}</button>
                                                        </FocusVisible>
                                                        <FocusVisible>
                                                            <button tabIndex={0} onClick={() => openLibraryDeleteModal(library)}
                                                                onKeyDown={(e) => handleKeyDown(e, openLibraryDeleteModal, library)}
                                                            >{translate != null ? translate[155].value : 'Delete Library'}</button>
                                                        </FocusVisible>
                                                    </div>
                                                </div>
                                            </div>
                                        </FocusVisible>)}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <div className="editLibraryModalTop" tabIndex={0} id="editLibraryModalTop">
                <div className="modal-content">
                    <div className="modal-header">
                        <span className="close" onClick={closeLibraryEditModal}>
                            <input type="image" src={cancel} alt="close model" /></span>
                        <h2>{translate != null ? translate[154].value : 'Edit Library'}</h2>
                    </div>
                    <div className="modal-body">
                        <p>Edit library details</p>
                        <form action="#" onSubmit={(e) => Save(e)}>
                            <span>library Name</span>
                            <FocusVisible>
                                <input
                                    type="text"
                                    name="libraryName" // Match the state property name
                                    id="libraryName"
                                    placeholder={libraryEdit.libraryName}
                                    value={libraryEdit.libraryName}
                                    style={{ border: NameValidation }}
                                    onChange={handleChange}
                                />
                            </FocusVisible>
                            <FocusVisible>
                                <div className="custom-select" >
                                    <select onChange={handleChange} name="visibility"
                                        id="visibility" value={libraryEdit.visibility}>

                                        <option value="" disabled>Private / Public</option>
                                        <option value="1">Private </option>
                                        <option value="2">Public </option>
                                    </select>
                                    <div className="select-icon">
                                        <img src={selectDown} alt="image arrow down to select" />
                                    </div>
                                </div>
                            </FocusVisible>
                            <FocusVisible>
                                <button type="submit">Save</button>
                            </FocusVisible>
                        </form>
                    </div>
                </div>
            </div>

            <div className="deleteLibraryModalTop" tabIndex={0} id="deleteLibraryModalTop">
                <div className="modal-content">
                    <div className="modal-header">
                        <span className="close" onClick={closeLibraryDeleteModal}><input type="image" src={cancel} alt="close delete model" /></span>
                        <h2>{translate != null ? translate[155].value : 'Delete Library'}</h2>
                    </div>
                    <div className="modal-body">
                        <p>:Are you sure you want to delete the library <br />
                            <span>{libraryEdit.libraryName}</span>
                        </p>
                        <h6>This action will delete the library and all books <br></br>!included
                            and is irreversible</h6>
                        <div className="btnsDeleteLib">
                            <FocusVisible>
                                <button className="cancel" onClick={closeLibraryDeleteModal}>Cancel</button>
                            </FocusVisible>
                            <FocusVisible>
                                <button className="delete" onClick={() => Delete(libraryEdit.id)}>Delete</button>

                            </FocusVisible>
                        </div>
                    </div>
                </div>
            </div>

            <div className="addLibraryModalTop" tabIndex={0} id="addLibraryModalTop">
                <div className="modal-content">
                    <div className="modal-header">
                        <span className="close" onClick={closeLibraryAddModal}>
                            <input type="image" src={cancel} alt="clode add model" /></span>
                        <h2>{translate != null ? translate[156].value : 'Add Library'}</h2>
                    </div>
                    <div className="modal-body">
                        <p>Add a new library</p>
                        <form action="#" onSubmit={(e) => Add(e)}>
                            <FocusVisible>
                                <input type="text" name="libraryName" required id="libraryName" value={libraryEdit.libraryName} placeholder="Library name" onChange={handleChange} />
                            </FocusVisible>
                            <FocusVisible>
                                <div className="custom-select" >
                                    <select onChange={handleChange} name="visibility" value={libraryEdit.visibility}
                                        id="visibility" required style={{ border: visibilitylValidation }}>

                                        <option disabled value="">Private / Public</option>
                                        <option value="1">Private </option>
                                        <option value="2">Public </option>
                                    </select>
                                    <div className="select-icon">
                                        <img src={selectDown} alt="image arrow down to select" />
                                    </div>
                                </div>
                            </FocusVisible>
                            <FocusVisible>
                                <button type="submit" style={{ cursor: 'pointer' }}>Add</button>
                            </FocusVisible>
                        </form >
                    </div>
                </div>
            </div>
            <Footer lang={'en'} />
        </>
    );
}

export default Library;
